<template>
    <div class="section-wrapper">
      <b-row>
        <b-col lg="12" sm="12">
          <body-card>
            <template v-slot:body>
              <b-row>
                <b-col lg="12" sm="12">
                  <template>
                      <div style="font-size:12px; font-color:white; background-color: #dddddd; padding:6px">
                          <h5 class="card-title text-center" style="margin-bottom: 0;color: #214162;font-size: 1.40rem;"> {{ $t('teaGardenConfig.tea_company') }} {{ $t('globalTrans.details') }}</h5>
                      </div>
                  </template>
                  <table class="table table-borderless">
                    <tr>
                      <th style="width: 10%"> {{$t('teaGardenConfig.company_name')}}</th>
                      <th style="width: 2%">:</th>
                      <td style="width: 30%">{{ ($i18n.locale === 'bn') ? item.company_name_bn : item.company_name_en }}</td>
                    </tr>
                    <tr>
                      <th style="width: 10%"> {{$t('teaGardenConfig.company_owner_name')}}</th>
                      <th style="width: 2%">:</th>
                      <td style="width: 30%">{{ ($i18n.locale === 'bn') ? item.owner_name_bn : item.owner_name_en }}</td>
                    </tr>
                    <tr>
                      <th style="width: 10%"> {{$t('teaGardenConfig.contact_no')}}</th>
                      <th style="width: 2%">:</th>
                      <td style="width: 30%">{{ EngBangNum(item.contact_no) }}</td>
                    </tr>
                    <tr>
                      <th style="width: 10%"> {{$t('teaGardenConfig.email')}}</th>
                      <th style="width: 2%">:</th>
                      <td style="width: 30%">{{ item.email }}</td>
                    </tr>
                    <tr>
                      <th style="width: 10%"> {{$t('teaGardenConfig.company_address')}}</th>
                      <th style="width: 2%">:</th>
                      <td style="width: 30%">{{ ($i18n.locale === 'bn') ? item.address_bn : item.address_en }}</td>
                    </tr>
                  </table>
                </b-col>
              </b-row>
            </template>
          </body-card>
        </b-col>
      </b-row>
    </div>
</template>
<script>
import { teaGardenServiceBaseUrl } from '@/config/api_config'
export default {
    name: 'Details',
    props: ['id'],
    data () {
      return {
        teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
        valid: null,
        loading: false,
        item: {},
        errors: [],
        detailsData: [],
        detailsItemId: ''
      }
    },
    created () {
      const tmp = this.getLawEntry()
      this.item = tmp
    },
    computed: {
      currentLocale () {
        return this.$i18n.locale
      }
    },
    methods: {
      EngBangNum (n) {
            if (this.$i18n.locale === 'bn') {
                return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
            } else {
                return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
            }
        },
      getLawEntry () {
        const tmpData = this.$store.state.list.find(item => item.id === this.id)
        return JSON.parse(JSON.stringify(tmpData))
      }
    }
}
</script>
